import $ from 'jquery';
import { numberWithCommas } from "./custom_format_input_number.js";

const UNIT_KG = 'kg';
$(document).on('turbolinks:load', function () {
  let isAddOneItemClicked = false;
  initSelect2ProductsAjax();
  initSelect2ProductsAjax(false, $('.js-source-products-code-ajax'));
  initSelect2ProductsAjax(false, $('.js-source-products-gname-ajax'));
  handleSelectProductAjax();
  handleSelectShopOrder();
  handleFilterDateRaditoChangeState();
  fillProductSelectShopOnOrder();
  setTabIndex();
  resetShopDropdown();

  initSelect2OrderSourceShops();

  var current_pathname = location.pathname;
  setTimeout(() => {
    if (current_pathname != "/orders/pick-up" || current_pathname != "/orders/pickup_orders") {
      const $select2Element = $('.js-orders-source-shops');

      if ($select2Element.length) {
        $select2Element.select2('open');

        $select2Element.on('select2:open', function () {
          setTimeout(() => {
            const $searchInput = $('.select2-search__field');
            if ($searchInput.length) {
              $searchInput.trigger('focus');
            }
          }, 100);
        });

        $(document).on('keydown', function (e) {
          const $dropdown = $('.select2-dropdown');

          if ($dropdown.is(':visible')) {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
              e.preventDefault();
            }
          }
        });

        $(document).on('keydown', function (e) {
          const $dropdown = $('.select2-dropdown');

          if ($dropdown.is(':visible') && e.key === 'Tab') {
            $select2Element.select2('close');
            e.preventDefault();
          }
        });
      }
    }
  }, 200);

  function trapFocus(modal) {
    const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const $focusableElements = modal.find(focusableSelectors).filter(':visible');
    const firstFocusable = $focusableElements.first()[0];
    const lastFocusable = $focusableElements.last()[0];

    modal.on('keydown', function (e) {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          // Shift + Tab
          if (document.activeElement === firstFocusable) {
            e.preventDefault();
            lastFocusable.focus();
          }
        } else {
          // Tab
          if (document.activeElement === lastFocusable) {
            e.preventDefault();
            firstFocusable.focus();
          }
        }
      }
    });
  }

  function resetSelect2() {
    $('.js-source-products-ajax').each(function (e) {
      const $select = $(this);
      if ($select.hasClass('select2-hidden-accessible')) {
        $select.select2('destroy');
        $select.select2();
      }
    });
  }

  $('.js-add-one-item').on('click', function () {
    isAddOneItemClicked = true;
  });

  $('.js-add-five-item').on('click', function () {
    isAddOneItemClicked = false;
  });

  let isProductSelectFocused = false;
  function focusOnProductSelect(addedItem) {
    if (isProductSelectFocused) return;

    const productSelect = $('.js-source-products-ajax').filter((_, el) => $(el).closest('.item').is(addedItem)).first();

    if (productSelect.length > 0 && !productSelect.val()) {
      productSelect.select2("open")

      isProductSelectFocused = true;

      setTimeout(() => {
        isProductSelectFocused = false;
      }, 500);
    } else {
      focusToFirstAmountField();
    }
  }

  $('#order-items-nested-form .content-nested-form')
    .on('cocoon:after-insert', function (e, added_item) {
      added_item.after($('#js-link-to-add-item'));

      initSelect2ProductsAjax(false, added_item.find('.js-source-products-ajax'));
      // initSelect2ProductsAjax();
      handleSelectProductAjax();
      setTimeout(() => focusOnProductSelect(added_item), 100);
    });

  $(document).on('shown.bs.modal', '#js-order-producing-modal', function () {
    handleSelectDirectiveTypeProduceOrder($('#js-order-producing-modal'));
  });

  $(document).on('shown.bs.modal', '#createNewProduceOrderModal', function () {
    $('.js-source-doughs').select2({
      dropdownParent: $('#createNewProduceOrderModal'),
      placeholder: "生地",
      language: { noResults: function () { return "見つかりません"; } }
    });

    $('.js-source-doughs').on('select2:select', function (e) {
      var data = e.params.data;

      $.ajax({
        url: '/manufacturing/products/get_by_dough',
        method: 'GET',
        data: {
          dough_id: data.id,
          'authenticity_token': $('meta[name="csrf-token"]').attr('content')
        }
      }).done(function () {
        handleSelectDirectiveTypeProduceOrder($('#createNewProduceOrderModal'));
      });
    });

    $('.js-source-products-ajax').on('select2:select', function (e) {
      var data = e.params.data;
      let product_unit_type = data.unit_type;

      $('#createNewProduceOrderModal').find('.js-product-unit-type').html(product_unit_type);
    });
  });

  $(document).on('shown.bs.modal', '#addUnexpectedProductsModal', function () {
    initSelect2OrderSourceShops($('.modal.js-order-item-modal'));

    $('.js-orders-source-shops, .js-orders-pick-up-source-shops').on('select2:select', function (e) {
      var data = e.params.data;
      var shop_id = data.id;

      $('.js-add-order-items-btn').removeClass('d-none');

      process_fill_shop_products(shop_id);
    });

    let modalStateVal = '';
    modalStateVal = $('.js-order-item-modal').find('#js_state_add_items').val();
    if (modalStateVal == 'load_page') {
      let shopId = $('input.js-selected-shop-id').val();

      if (shopId == '' || shopId == undefined) {
        shopId = $('select.js-orders-source-shops').val();
        shopId = $('select.js-orders-puck-up-source-shops').val();
      }

      if (shopId != '' && shopId != undefined) {
        process_fill_shop_products(shopId);
      }
    }

    $('#order-items-nested-form')
      .on('cocoon:after-insert', function (e, added_item) {
        added_item.after($('#js-link-to-add-item'));

        initSelect2ProductsAjax($('.modal.js-order-item-modal'), added_item.find('.js-source-products-ajax'));
        handleSelectProductAjax();
      });
  });

  function handleSelectDirectiveTypeProduceOrder($dropdownParent) {
    $('.js-select-directive-type').select2({
      dropdownParent: $dropdownParent,
      language: { noResults: function () { return "見つかりません"; } }
    });

    $('.js-select-directive-type').on('select2:select', function (e) {
      let data = e.params.data;
      let elem = $(this).parent().parent().find('.js-unit-type-col');

      if (data.id == 'directive_product') {
        $(elem).html('個');
      } else if (data.id == 'directive_dough') {
        $(elem).html('本');
      } else if (data.id == 'directive_remain_dough') {
        $(elem).html('キロ');
      }
    });
  }

  function initSelect2OrderSourceShops(onModal = false) {
    let options = {
      ajax: {
        url: '/shops.json',
        dataType: 'json',
        data: function (params) {
          return {
            q: { name_or_code_cont: params.term },
            page: params.page || 1
          }
        },
        processResults: function (data, params) {
          params.page = params.page || 1;

          return {
            results: $.map(data, function (item) {
              let item_name = item.name;
              let item_code = item.code;

              return { text: item_code + " " + item_name, id: item.id }
            })
          };
        },
        cache: true,
        minimumInputLength: 2
      },
      placeholder: "店舗コード - 店舗名",
      language: {
        noResults: function () {
          return "見つかりません";
        }
      },
      selectOnClose: false
    };

    if (onModal) {
      options.dropdownParent = onModal;
    }

    $('.js-orders-source-shops').select2(options);
    $('.js-orders-puck-up-source-shops').select2(options);
  }

  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });

  $(document).on('click', '.js-btn-update-order-item-status', function (e) {
    e.preventDefault();

    let item_id = $(this).data('item-id');
    let update_status = '';
    if ($(this).hasClass('btn-order-item-status-not_accept_yet')) {
      update_status = 'planed_to_deliver';
    } else if ($(this).hasClass('btn-order-item-status-planed_to_deliver')) {
      update_status = 'not_accept_yet';
    }

    if (item_id != "" && update_status != "") {
      $.ajax({
        url: '/order_items/update_status',
        method: 'POST',
        data: {
          id: item_id,
          order_item: { status: update_status },
          'authenticity_token': $('meta[name="csrf-token"]').attr('content')
        }
      }).done(function () {
      });
    }
  });

  $(document).on('change', '.order-items-data .js-item-checkbox', function () {
    var productName = $(this).data("product-name");
    var isChecked = $(this).prop("checked");
    $.each($(".order-items-data .js-item-checkbox:checked"), function (i, el) {
      if (productName == $(el).data("product-name")) {
        $(el).prop("checked", false)
      }
    });

    $(this).prop("checked", isChecked);
  });

  $(document).on('click', '.js-add-order-items-btn', function (e) {
    e.preventDefault();

    let btn_state = $(this).data('btn-state');
    var shopId = '';

    if (btn_state == "onModal") {
      shopId = $('input.js-selected-shop-id').val();

      if (shopId == '' || shopId == undefined) {
        shopId = $('select.js-orders-source-shops').val();
      }
    } else {
      shopId = $('.js-confirm-fill-products-modal .js-current-select-shop-id').val();
    }

    $('.js-order-item-modal').modal('hide');
    getOrderItemsData(shopId);
  });

  $(document).on('click', '.js-check-duplicate-product-btn', function (e) {
    e.preventDefault();

    let items = $('.order-items-data input.js-item-checkbox:checked');

    var productIdsList = [];
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        var productId = $(items[i]).data("product-id");
        productIdsList.push(productId);
      }
    }

    var isConfirm = false;

    $.each($(".js-select-product-col select"), function (i, dropdown) {
      if ($(dropdown).val() != "" && $.inArray(parseInt($(dropdown).val()), productIdsList) !== -1) {
        isConfirm = true;
      }
    });

    if (isConfirm) {
      $('#confirm-modal').modal('show');
    } else {
      $(".js-append-order-items-checked-btn").trigger("click");
    }
  });

  $(document).on('click', '.js-append-order-items-checked-btn, .js-confirm-order-item-modal-ok-btn', function (e) {
    e.preventDefault();

    let onModalDeliver = false;
    let btnOnModal = $(this).data('on-modal');
    let confirmOverWrite = false;

    if ($(this).hasClass('js-confirm-order-item-modal-ok-btn')) {
      confirmOverWrite = true;
    }

    if (btnOnModal != undefined && btnOnModal == 'add_products_deliver_modal') {
      onModalDeliver = true;
    }

    var items = $('.order-items-data input.js-item-checkbox:checked');

    if (onModalDeliver) {
      $('.js-add-order-items-data-modal').modal('hide');
      close_confirm_modal();
      $('.js-order-item-modal').modal('show');
      $('.js-order-item-modal').find('#js_state_add_items').val('add_history_items');
      processAppendShopProductsToModal(items, confirmOverWrite, true);
    } else {
      processAppendShopProductsToModal(items, confirmOverWrite);

      close_confirm_modal();
      close_modal_add_order_items();
    }
  });

  function processAppendShopProductsToModal(items, confirmOverWrite = false, onModalDeliver = false) {
    let existed_items = [];
    if (confirmOverWrite) {
      $('#order-items-nested-form .row-content').find('.row.item').remove();
    } else {
      existed_items = $('#order-items-nested-form .row-content').find('.row.item');
    }

    for (let i = 0; i < items.length; i++) {
      $('#order-items-nested-form .row-content a.js-add-one-item').click();
    }

    for (let i = existed_items.length; i < items.length + existed_items.length; i++) {
      let product_items = $('#order-items-nested-form .row-content').find('.row.item');
      let inserted_item = product_items[i];
      let item_data = items[i - existed_items.length];

      var productName = $(item_data).data("product-name");
      var productId = $(item_data).data("product-id");
      var amount = $(item_data).data("amount");
      var unitPrice = $(item_data).data("unit-price");
      var priceTotal = $(item_data).data("price");

      var newOption = new Option(productName, productId, true, true);
      $(inserted_item).find('.js-source-products-ajax').append(newOption).trigger('change');

      $(inserted_item).find('.js-product-price-col .js-product-price').val(unitPrice);
      $(inserted_item).find('input.js-order-item-amount').val(amount);
      $(inserted_item).find('input.js-order-total-fee').val(priceTotal);
    }
  }

  $(document).on('click', '.js-close-modal-add-order-items', function (e) {
    e.preventDefault();
    $('.js-add-order-items-data-modal').modal('hide');
    $('.js-add-order-items-btn').focus();

    let onModalDeliver = false;
    let btnOnModal = $(this).data('on-modal');
    if (btnOnModal != undefined && btnOnModal == 'add_products_deliver_modal') {
      onModalDeliver = true;
    }

    if (onModalDeliver) {
      close_modal_add_order_items();
      $('.js-order-item-modal').modal('show');
    } else {
      close_modal_add_order_items();
    }
  });

  $(document).on('click', '.js-btn-import-order', function (e) {
    e.preventDefault();

    $(".js-input-file-field").trigger("click");
  });

  $(document).on('click', '.js-confirm-cancel-btn', function (e) {
    e.preventDefault();

    close_confirm_modal();
  });

  function getOrderItemsData(shopId) {
    if (shopId != "") {
      $.ajax({
        url: '/orders/get_orders_by_shop',
        method: 'GET',
        data: { id: shopId, 'authenticity_token': $('meta[name="csrf-token"]').attr('content') }
      }).done(function (response) {
        $(".js-add-order-items-data-modal .order-items-data").html("");
        var str = "";
        if (response.data.length > 0) {
          $.each(response.data, function (i, order) {
            if (order.items.length != 0) {
              str += `<div class="mt-3 date-strf">${order.date_strf}</div>
            <div class="card mt-1">
              <div class="card-body">
                <div class="workers-list-wrapper modal-order-items-list-items">
                  <div class="workers-list">
                    <div class="row row-head">
                      <div class="col-1"></div>
                      <div class="col-4">商品名</div>
                      <div class="col-2">個数</div>
                      <div class="col-2">単価</div>
                      <div class="col-3">金額</div>
                    </div>
                    <div class="row-content">`;

              $.each(order.items, function (j, item) {
                str += `<div class="row row-item">
                        <div class="col-1 d-margin-auto text-center">
                          <input type="checkbox" class="js-item-checkbox" data-product-name="${item.product_name}" data-product-id="${item.product_id}"
                          data-amount="${item.amount}" data-unit-price="${item.unit_price}" data-price="${item.price}"
                          data-product-code="${item.product_code}" data-product-general-name="${item.product_general_name}" />
                        </div>
                        <div class="col-4 d-margin-auto">
                          ${item.product_name}
                        </div>
                        <div class="col-2 d-margin-auto">
                          ${numberWithCommas(item.amount)}
                        </div>
                        <div class="col-2 d-margin-auto">
                          ¥${numberWithCommas(item.unit_price)}
                        </div>
                        <div class="col-3 d-margin-auto">
                          ¥${numberWithCommas(item.price)}
                        </div>
                      </div>`;
              });

              str += `</div>
                  </div>
                </div>
              </div>
            </div>
          </div>`;
            }
          });
        } else {
          str += `<div class="card mt-1">
              <div class="card-body">
                <div class="workers-list-wrapper modal-order-items-list-items">
                  <div class="workers-list">
                    <div class="row row-head">
                      <div class="col-1"></div>
                      <div class="col-4">商品名</div>
                      <div class="col-2">個数</div>
                      <div class="col-2">単価</div>
                      <div class="col-3">金額</div>
                    </div>
                    <div class="row-content">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>`;
        }

        $(".js-add-order-items-data-modal .order-items-data").append(str);
        $('.js-add-order-items-data-modal').modal('show');
      });
    }
  }

  function close_modal_add_order_items() {
    initSelect2ProductsAjax();
    $('.js-add-order-items-data-modal').modal('hide');
  }

  function close_confirm_modal() {
    initSelect2ProductsAjax();
    $('#confirm-modal').modal('hide');
  }

  function fillProductSelectShopOnOrder() {
    $('.js-orders-source-shops').on('select2:select', function (e) {
      var data = e.params.data;
      let current_selected_shop_id = $('input.js-selected-shop-id').val();
      var shopDropdownCount = localStorage.getItem("shopDropdownCount");

      $('.js-confirm-fill-products-modal .js-current-select-shop-id').val(data.id);
      // $(".js-add-order-items-btn").focus();

      if (parseInt(shopDropdownCount) > 0) {
        $('.js-confirm-fill-products-modal').modal('show');
      } else {
        process_fill_shop_products(data.id);
        localStorage.setItem("shopDropdownCount", shopDropdownCount + 1);
        focusToFirstAmountField();
      }
    });
  }

  $(document).on('click', '.js-confirm-fill-products-modal .js-cancel-btn', function (e) {
    e.preventDefault();

    let current_selected_shop_id = $('input.js-selected-shop-id').val();
    $('.js-orders-source-shops').val(current_selected_shop_id).trigger('change');
    $('.js-confirm-fill-products-modal').modal('hide');
    $('.js-orders-source-shops').select2('focus');

    resetSelect2();
  });

  $(document).on('click', '.js-confirm-fill-products-modal .js-confirm-ok-btn', function (e) {
    e.preventDefault();

    let current_selected_shop_id = $('input.js-current-select-shop-id').val();
    $('input.js-selected-shop-id').val(current_selected_shop_id);
    $('.js-orders-source-shops').val(current_selected_shop_id).trigger('change');
    $('.js-confirm-fill-products-modal').modal('hide');
    $('.js-orders-source-shops').select2('focus');

    process_fill_shop_products(current_selected_shop_id);
    setTabIndex();
  });

  $(document).on('change', '.js-input-file-field', function (e) {
    e.preventDefault();

    $(".js-form-import-csv-btn").trigger("click");
    $('.js-importing-notify-modal').modal('show');
  });

  function process_fill_shop_products(shop_id) {
    $.ajax({
      url: '/shop_products/list_order_shop_products',
      method: 'POST',
      data: { shop_id: shop_id, 'authenticity_token': $('meta[name="csrf-token"]').attr('content') }
    }).done(function (rsp) {
      $('#order-items-nested-form .row-content').find('.row.item').remove();

      if (rsp.length > 0) {
        for (let i = 0; i < rsp.length; i++) {
          $('#order-items-nested-form .row-content a.js-add-one-item').click();
        }

        for (let i = 0; i < rsp.length; i++) {
          let product_items = $('#order-items-nested-form .row-content').find('.row.item');
          let inserted_item = product_items[i];

          var newOption = new Option(rsp[i].text, rsp[i].id, true, true);
          $(inserted_item).find('.js-source-products-ajax').append(newOption).trigger('change');
          $(inserted_item).find('.js-product-price-col .js-product-price').val(numberWithCommas(rsp[i].display_price));
          $(inserted_item).find('label.js-product-unit-type').html(rsp[i].unit_type);

          if (rsp[i].unit_type == UNIT_KG) {
            $(inserted_item).find('input.js-order-item-amount').removeClass('int-field').addClass('float-field');
          }
        }
      } else {
        $('#order-items-nested-form .row-content a.js-add-five-items').click();
      }
    });
  }

  function handleFilterDateRaditoChangeState() {
    let item_dom = 'input[type="radio"][name="order[delivery_options]"]';

    $(item_dom).change(function () {
      let parent = $(this).parents('.js-filter-delivery-date-options');
      let input_dom1 = 'input[name="order[delivery_date1]"';
      let input_dom2 = 'input[name="order[delivery_date2]"';
      let input_date1 = $(parent).find(input_dom1);
      let input_date2 = $(parent).find(input_dom2);

      if (this.value == 'on_time') {
        $(input_date1).val('');
        $(input_date2).val('');
        $(input_date1).prop('disabled', true);
        $(input_date2).prop('disabled', true);
      } else if (this.value == 'range_time') {
        $(input_date1).prop('disabled', false);
        $(input_date2).prop('disabled', false);
      } else if (this.value == 'all_time') {
        $(input_date1).val('');
        $(input_date2).val('');
      }
    });
  }

  function initSelect2ProductsAjax(onModal = false, $specific_item = null) {
    let $dom_elem = $('.js-source-products-ajax');

    if ($specific_item != null) {
      $dom_elem = $specific_item;
    }

    let options = {
      ajax: {
        url: '/products.json',
        dataType: 'json',
        data: function (params) {
          let query = { name_or_code_or_general_name_or_client_products_special_product_name_cont: params.term };

          let except_product_ids = [];
          let items = $('.js-select-product-col select.js-source-products-ajax');
          for (let i = 0; i < items.length; i++) {
            let p_id = $(items[i]).val();
            if (p_id != null && p_id != "") {
              except_product_ids.push(p_id);
            }
          }

          return {
            q: query,
            shop_id_selected: $('select.js-orders-source-shops').val(),
            except_product_ids: except_product_ids,
            page: params.page || 1
          }
        },
        processResults: function (data, params) {
          params.page = params.page || 1;

          return {
            results: $.map(data, function (item) {
              let item_name = item.name;
              let item_price = item.price;
              let item_unit_type = item.unit_type_localization;

              if (item.client_id_selected != null) {
                for (let i = 0; i < item.client_products.length; i++) {
                  if (item.client_id_selected == item.client_products[i].client_id) {
                    item_name = item.client_products[i].special_product_name;
                    item_price = item.client_products[i].price;

                    break;
                  }
                }
              }

              let item_text_display = item_name + '、' + item.code;
              if (item.general_name != null && item.general_name != '') {
                item_text_display += '、' + item.general_name;
              }

              return { text: item_text_display, id: item.id, price: item_price, unit_type: item_unit_type }
            })
          };
        },
        cache: true,
        minimumInputLength: 1
      },
      language: {
        noResults: function () {
          return "見つかりません";
        }
      },
      selectOnClose: false
    };

    if (onModal) {
      options.dropdownParent = onModal;
    }

    $dom_elem.select2(options);
  }

  function handleSelectShopOrder() {
    $('.js-orders-source-shops').on('select2:select', function (e) {
      var data = e.params.data;
      var shop_id = data.id;

      $(this).closest(".orders-form-block").find(".js-add-order-items-btn").removeClass("d-none");
    });
  }

  function correctNumberFieldByFormatted(input_value, item_type) {
    if (input_value == undefined || input_value == "") {
      return "";
    }

    if (item_type == 'int') {
      return parseInt(input_value.replace(',', ''));
    } else {
      return parseFloat(input_value.replace(',', ''));
    }
  }

  function handleSelectProductAjax() {
    $('.js-source-products-ajax').on('select2:select', function (e) {
      var data = e.params.data;

      var current_row = $(e.currentTarget).parents('.row.item');
      var item_amount = $(current_row).find('.js-order-item-amount-col input.js-order-item-amount').val().trim();
      var item_price = numberWithCommas(data.price);

      $(current_row).find('.js-order-item-amount-col label.js-product-unit-type').html(data.unit_type);
      $(current_row).find('.js-product-price-col input.js-product-price').val(item_price);
      $(current_row).find('.js-source-products-ajax').val(data.id).trigger('change');
      $(current_row).find('.js-select-product-col').find('.select2-selection__rendered').text(data.text);

      if (item_amount != "") {
        item_amount = item_amount.replace(',', '');
        var order_item_total_fee = data.price * parseFloat(item_amount);
        order_item_total_fee = parseInt(order_item_total_fee);

        var display_order_item_total_fee = numberWithCommas(order_item_total_fee);
        $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val(display_order_item_total_fee);
      }

      if (data.unit_type == UNIT_KG) {
        $(current_row).find('input.js-order-item-amount').removeClass('int-field').addClass('float-field');
        $(current_row).find('input.js-order-item-amount').removeClass('js-format-int-field').addClass('js-format-float-field');
      } else {
        $(current_row).find('input.js-order-item-amount').removeClass('float-field').addClass('int-field');
        $(current_row).find('input.js-order-item-amount').removeClass('js-format-float-field').addClass('js-format-int-field');
      }
    });

    $('input.js-order-item-amount').keyup(function (event) {
      var current_row = $(this).parents('.row.item');
      var product_price = $(current_row).find('.js-product-price-col input.js-product-price').val();
      var current_input = $(this).val().trim();

      product_price = correctNumberFieldByFormatted(product_price);
      current_input = correctNumberFieldByFormatted(current_input);

      if (current_input == undefined || current_input == '') {
        $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val('');
      }

      if ($.isNumeric(current_input) && $.isNumeric(product_price)) {
        let item_amount_converted = parseFloat(current_input);
        let product_price_converted = parseFloat(product_price);
        var order_item_total_fee = item_amount_converted * product_price_converted;
        order_item_total_fee = parseInt(order_item_total_fee);

        var display_order_item_total_fee = numberWithCommas(order_item_total_fee);
        $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val(display_order_item_total_fee);
      }
    });

    $('input.js-product-price').keyup(function (event) {
      var current_row = $(this).parents('.row.item');
      let order_item_amount = $(current_row).find('.js-order-item-amount-col input.js-order-item-amount').val();
      let current_input = $(this).val().trim();

      order_item_amount = correctNumberFieldByFormatted(order_item_amount);
      current_input = correctNumberFieldByFormatted(current_input);

      if ($.isNumeric(current_input) && $.isNumeric(order_item_amount)) {
        let item_amount_converted = parseFloat(current_input);
        let order_item_amount_converted = parseFloat(order_item_amount);
        var order_item_total_fee = item_amount_converted * order_item_amount_converted;
        order_item_total_fee = parseInt(order_item_total_fee);

        var display_order_item_total_fee = numberWithCommas(order_item_total_fee);
        $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val(display_order_item_total_fee);
      }

      if (current_input == null || current_input == "") {
        $(current_row).find('.js-order-total-fee-col input.js-order-total-fee').val("");
      }
    });
  }

  function setTabIndex() {
    localStorage.setItem("tabIndex", 0);
  }

  function resetShopDropdown() {
    localStorage.setItem("shopDropdownCount", 0);
  }

  // Press Tab and Shift Tab to next and previous amount element
  document.onkeydown = function (e) {
    if (e.ctrlKey) {
      // e.preventDefault(); If uncomment this line, we can not copy & paste using ctrl + c, v

      if (e.keyCode == 79) {
        window.location.href = '/orders/new';
      }
    }
  };

  function focusToFirstAmountField() {
    var el = $("#order-items-nested-form .js-order-item-amount");
    var totalEl = el.length;
    if (totalEl > 0) {
      $($(el)[0]).focus();
    }
  }

  // Add CSS for tooltip
  const errorStyles = `
  .error-tooltip {
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
    margin-top: 5px;
    font-size: 12px;
    pointer-events: none;
  }

  .input-error {
    border-color: #dc3545 !important;
  }

  .input-wrapper {
    position: relative;
  }
`;

  // Add style tag to head
  const styleSheet = document.createElement("style");
  styleSheet.innerText = errorStyles;
  document.head.appendChild(styleSheet);

  // Track the first error field
  let $firstErrorField = null;

  function showFieldError($field, message) {
    const $parent = $field.parent();
    $field.addClass('input-error');

    // Only show tooltip for the first error field
    if (!$firstErrorField) {
      $firstErrorField = $field;

      // Create tooltip only for first error
      let $tooltip = $('<div>', {
        class: 'error-tooltip',
        text: message
      });
      $parent.append($tooltip);

      // Show tooltip immediately
      $tooltip.show();

      // Hide tooltip on any mouse movement within the document
      $(document).one('mousemove', function () {
        hideAllTooltips();
      });
    }
  }

  function hideAllTooltips() {
    $('.error-tooltip').remove();
    $firstErrorField = null;
  }

  function clearFieldError($field) {
    $field.removeClass('input-error');
    if ($field.is($firstErrorField)) {
      hideAllTooltips();
    }
  }

  function InvalidMsg(textbox) {
    const $field = $(textbox);
    const $row = $field.closest('.row.item');

    if (!$row.length) return;

    const productId = $row.find('.js-source-products-ajax').val() || '';
    const amount = $row.find('.js-order-item-amount').val() || '';
    const price = $row.find('.js-product-price').val() || '';
    const memo = $row.find('.js-order-item-memo').val() || '';

    textbox.setCustomValidity('');
    clearFieldError($field);

    const isRowFilled = productId || amount || price || memo;
    $field.prop('required', isRowFilled);

    if ($field.prop('required') && !textbox.value) {
      textbox.setCustomValidity('ご記入ください');
      showFieldError($field, 'ご記入ください');
    } else if (textbox.value.length > 9) {
      textbox.setCustomValidity('最大9桁');
      showFieldError($field, '最大9桁');
    }
  }


  function validateOrderForm() {
    let isValid = true;
    $firstErrorField = null;
    hideAllTooltips();

    $('.order-form-inline input[required]').each(function () {
      InvalidMsg(this);
      if (this.validationMessage) {
        isValid = false;
      }
    });

    $('.row.item').each(function () {
      const $row = $(this);
      const productId = $row.find('.js-source-products-ajax').val();
      const amount = $row.find('.js-order-item-amount').val();
      const price = $row.find('.js-product-price').val();
      const memo = $row.find('.js-order-item-memo').val();

      if (productId || amount || price || memo) {
        $row.find('input[required]').each(function () {
          InvalidMsg(this);
          if (this.validationMessage) {
            isValid = false;
          }
        });
      }
    });

    return isValid;
  }

  $(document).ready(function () {
    $('.order-form-inline input[required]').each(function () {
      $(this).attr({
        oninvalid: "InvalidMsg(this);",
        oninput: "InvalidMsg(this);"
      });
    });

    $('.order-form-inline input').on('input', function () {
      InvalidMsg(this);
    });

    $('.order-form-inline input[required]').each(function () {
      InvalidMsg(this);
    });

    $(document).on('click', function () {
      hideAllTooltips();
    });

    $('.order-form-inline').on('mouseleave', function () {
      hideAllTooltips();
    });
  });

  function handleFormSubmission(e) {
    e.preventDefault();

    if (!validateOrderForm()) {
      return false;
    }

    $('.order-form-inline')[0].submit();
  }

  $(document).ready(function () {

    $('.order-form-inline input').on('blur', function () {
      InvalidMsg(this);
    });

    let originalValue = null;
    let isEditMode = false;
    let $currentField = null;
    let fieldValues = new Map();

    $(document).on('click', 'input, textarea', function (e) {
      enterEditMode($(this));
    });

    function getAllVisibleFields() {
      return $(".order-form-inline").find('input, select, button, a, textarea, .select2-selection')
        .filter(':visible')
        .not('.js-order-total-fee, .select2-hidden-accessible');
    }

    function focusField($field) {
      $field.focus();
      if ($field.hasClass('select2-selection')) {
        $field.trigger('click');
      }
    }

    function enterEditMode($field) {
      isEditMode = true;
      $currentField = $field;
      $field.addClass('editing');

      fieldValues.set($field[0], $field.val());

      $field.focus();
      setTimeout(() => {
        const element = $field[0];

        if (!element) return;
        if (
          (element.tagName === "INPUT" &&
            ["text", "search"].includes(element.type)) ||
          element.tagName === "TEXTAREA"
        ) {
          const length = element.value.length;
          element.setSelectionRange(length, length);
        }
      }, 0);
    }

    function exitEditMode($field, moveToNext = true) {
      isEditMode = false;
      $field.removeClass('editing');

      fieldValues.delete($field[0]);

      if (moveToNext) {
        const $allFields = getAllVisibleFields();
        const currentIndex = $allFields.index($field);
        const $nextField = $allFields.eq(currentIndex + 1);
        if ($nextField.length) {
          setTimeout(() => focusField($nextField), 0);
        }
      }
    }

    $(document).on('focus', 'input, textarea, select, button, .select2-selection', function (e) {
      const $field = $(this);
      if (!fieldValues.has($field[0])) {
        fieldValues.set($field[0], $field.val());
      }

      if (!isEditMode) {
        e.preventDefault();
        if (window.getSelection) {
          window.getSelection().removeAllRanges();
        }
        isEditMode = false;
      }
    });

    $(document).on('blur', 'input, textarea, select, button, .select2-selection', function (e) {
      isEditMode = false;
    });

    $('input[type="submit"]').removeAttr('data-disable-with');
    const $form = $(".order-form-inline");
    $($form).on('keydown', 'input, textarea', function (e) {
      const $field = $(this);

      if (!isEditMode) {
        if ((e.key.length === 1 && e.key.match(/^[a-zA-Z0-9]$/)) || e.key.match(/^[\u0020-\u007E]$/)) {
          $field.val('');
          isEditMode = true;
        }
      }
    });

    $($form).on('blur', 'input, textarea', function () {
      isEditMode = false;
    });

    $form.on('keydown', 'input, select, button, a, textarea, .select2-selection, input[type="submit"]', function (e) {
      clearFieldError($(this));
      const $field = $(this);
      const $allFields = getAllVisibleFields();
      const fieldIndex = $allFields.index($field);
      let $targetField;

      function isSelect2Open() {
        return $('.select2-container--open').length > 0;
      }

      if (isSelect2Open()) {
        if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
          e.preventDefault();
        }
        return;
      }
      switch (e.key) {
        case 'ArrowRight':
          if (isEditMode) return;
          if (fieldIndex < $allFields.length - 1) {
            $targetField = $allFields.eq(fieldIndex + 1);
          }
          break;
        case '*':
        case 'ArrowLeft':
          if (isEditMode) return;
          if (fieldIndex > 0) {
            $targetField = $allFields.eq(fieldIndex - 1);
          }
          break;
        case 'ArrowDown':
          if (isEditMode) return;

          const $currentRow = $field.closest('.row');
          const $currentOrderRow = $currentRow.closest('.orders-form-block');

          if ($currentOrderRow.length) {
            const $firstItemRow = $('.row.item:first');
            const currentFieldName = $field.attr('name') || '';
            const currentControl = $field.attr('aria-controls') || '';

            switch (true) {
              case currentFieldName.includes('infomart_order_code'):
                $targetField = $firstItemRow.find('.js-source-products-ajax:first'); // 商品名
                break;
              case currentFieldName.includes('delivery_at'):
                $targetField = $firstItemRow.find('.js-order-item-amount:first'); // 個数
                break;
              case currentFieldName.includes('order_at'):
                $targetField = $firstItemRow.find('.js-product-price:first'); // 単価
                break;
              case currentControl === 'select2-order_shop_id-container':
                $targetField = $firstItemRow.find('.js-order-item-memo:first'); // メモ
                break;
              case $field.hasClass('js-add-order-items-btn'):
                $targetField = $firstItemRow.find('a.btn-sm-destroy:first'); // 操作
                break;
            }
          } else if ($currentRow.hasClass('row')) {
            const $currentItem = $field.closest('.row.item');

            if ($currentItem.length) {
              const $allDeleteOrderRows = $('.content-nested-form .js-confirm-delete-order').closest('.row.item');
              const isLastDeleteOrderRow = $currentItem.is($allDeleteOrderRows.last());
              if (isLastDeleteOrderRow) {
                const $nextAddFiveItemsButton = $('.js-add-five-items').first();
                if ($nextAddFiveItemsButton.length) {
                  $targetField = $nextAddFiveItemsButton;
                  break;
                }
              } else {
                const $nextItem = $currentItem.next('.row.item');
                if ($nextItem.length) {
                  const currentFieldIndex = $currentItem
                    .find('input, select, button, a, textarea, .select2-selection')
                    .filter(':visible')
                    .not('.js-order-total-fee, .select2-hidden-accessible')
                    .index($field);

                  $targetField = $nextItem
                    .find('input, select, button, a, textarea, .select2-selection')
                    .filter(':visible')
                    .not('.js-order-total-fee, .select2-hidden-accessible')
                    .eq(currentFieldIndex);
                }
              }
            } else {
              const $cancelButton = $('.btn-cancel').first();
              if ($cancelButton.length) {
                $targetField = $cancelButton;
                break;
              }
            }
          }
          break;
        case 'ArrowUp':
          if (isEditMode) return;
          const $currentItem = $field.closest('.row.item');

          if ($currentItem.length) {
            if ($currentItem.is(':first-child')) {
              const $parent = $field.closest('.js-select-product-col');
              switch (true) {
                case $parent.length && !$field.is($parent): // Từ 商品名
                  $targetField = $('[name*="infomart_order_code"]');
                  break;
                case $field.hasClass('js-order-item-amount'): // Từ 個数
                  $targetField = $('[name*="delivery_at"]');
                  break;
                case $field.hasClass('js-product-price'): // Từ 単価
                  $targetField = $('[name*="order_at"]');
                  break;
                case $field.hasClass('js-order-item-memo'): // Từ メモ
                  $targetField = $('.js-orders-source-shops');
                  break;
                case $field.hasClass('btn-sm-destroy'): // Từ 操作
                  const $orderHistoryBtn = $('.js-add-order-items-btn:visible');
                  $targetField = $orderHistoryBtn.length && !$orderHistoryBtn.hasClass('d-none')
                    ? $orderHistoryBtn
                    : $('.js-orders-source-shops');
                  break;
              }
            } else {
              const $prevItem = $currentItem.prev('.row.item');
              if ($prevItem.length) {
                const currentFieldIndex = $currentItem
                  .find('input, select, button, a, textarea, .select2-selection')
                  .filter(':visible')
                  .not('.js-order-total-fee, .select2-hidden-accessible')
                  .index($field);

                $targetField = $prevItem
                  .find('input, select, button, a, textarea, .select2-selection')
                  .filter(':visible')
                  .not('.js-order-total-fee, .select2-hidden-accessible')
                  .eq(currentFieldIndex);
              }
            }
          } else {
            $targetField = $('.js-add-five-items');
            const $newCurrentItem = $field.closest('.row');
            if ($newCurrentItem.find('.js-add-five-items').length) {
              const $nextRow = $newCurrentItem.prev('.row');
              const $destroyBtn = $nextRow.find('.btn-sm-destroy').first();
              if ($destroyBtn.length) {
                $targetField = $destroyBtn;
              }
            }
          }
          break;
        case 'Escape':
          if (isEditMode && $field) {
            e.preventDefault();
            const originalVal = fieldValues.get($field[0]);
            if (originalVal !== undefined) {
              $field.val(originalVal);
            }
          }
          break;
        case 'Enter':
          e.preventDefault();
          if ($field.hasClass('js-add-order-items-btn')) {
            $('.js-add-order-items-btn').trigger('click');
            break;
          }

          if ($field.hasClass('js-confirm-delete-order')) {
            $(this).trigger('click');
            break;
          }

          if ($field.hasClass('js-add-five-items')) {
            $(".js-add-five-items").trigger('click');
            break;
          }

          if ($field.hasClass('btn-cancel')) {
            $(".btn-cancel").trigger('click');
            break;
          }

          if ($field.hasClass('btn-save')) {
            // $(".order-form-inline .btn-save").trigger("click");
            handleFormSubmission(e);
            break;
          }

          if (isEditMode) {
            exitEditMode($field);
          } else {
            enterEditMode($field);
          }
          break;
        case 'F2':
          e.preventDefault();
          const $currentRowDelete = $(e.target).closest('.row.item');
          $currentRowDelete.find('.js-confirm-delete-order').trigger('click');
          break;
        case 'F3':
          e.preventDefault();
          const currentRow = $(e.target).closest('.row.item');
          const currentRowClone = currentRow.clone(true);

          const timestamp = Date.now();
          const itemId = crypto.randomUUID()
          currentRowClone.attr('data-item-id', itemId);
          currentRowClone.data('item-id', itemId);

          currentRowClone.find('input, select, textarea').each(function () {
            const $formField = $(this);
            if ($formField.hasClass('select2-hidden-accessible')) {
              // Update IDs to avoid duplication
              const oldId = $formField.attr('id');
              const newId = oldId.replace(/\d+/, timestamp);
              $formField.attr('id', newId);
              $formField.attr('name', $formField.attr('name').replace(/\d+/, timestamp));

              // Clear select2
              currentRowClone.find('[data-select2-id]').removeAttr('data-select2-id');
              $formField.removeClass('select2-hidden-accessible')
                .removeData('select2');
              currentRowClone.find('.select2-container').remove();
              $formField.find('option:selected').removeAttr('selected');
              $formField.val(null);
            } else {
              $formField.val('');
            }
          });

          currentRow.after(currentRowClone);
          initSelect2ProductsAjax(false, currentRowClone.find('.js-source-products-ajax'));
          break;
        case 'Tab':
          if (isEditMode) return;
          const isShiftTab = e.shiftKey && e.key === 'Tab';

          e.preventDefault();
          const currentIndex = $allFields.index($field);
          let nextIndex = isShiftTab ? currentIndex - 1 : currentIndex + 1;

          while (nextIndex >= 0 && nextIndex < $allFields.length) {
            const $nextField = $allFields.eq(nextIndex);
            const $nextCol = $nextField.closest('.js-order-total-fee-col');

            if (!$nextCol.length) {
              $targetField = $nextField;
              break;
            }

            nextIndex = isShiftTab ? nextIndex - 1 : nextIndex + 1;
          }

          if ($targetField) {
            $targetField.focus();
          }
          break;
        case '/':
          if ($field.hasClass('editing')) {
            return;
          }

          e.preventDefault();
          $field.val('');
          $field.addClass('editing');
          $field.focus();
          break;
        // case '*':
        //   e.preventDefault();
        //   enterEditMode($field);
        //   break;
      }

      if ($targetField?.length) {
        setTimeout(function () {
          $targetField.focus();
        }, 0);
        e.preventDefault();

        if ($targetField.hasClass('select2-selection')) {
          $targetField.trigger('click');
        }

        if ($targetField[0].type == 'submit') {
          $('input[type="submit"]').on('focus', function () {
            $(this).attr('data-disable-with', '登録');
          });
        }
      }
    });
  });

  // singledate - arrow left and arrow right
  $(document).ready(function () {
    const $form = $(".order-form-inline");
    if (!$form.length) return;

    let activeInput = null;
    let isModalVisible = false;
    $("input.singledate").each(function (index) {
      const $input = $(this);
      if ($input.data("daterangepicker")) {
        const uniqueClass = `daterangepicker-${index + 1}`;
        const daterangepickerInstance = $input.data("daterangepicker");
        daterangepickerInstance.container.addClass(uniqueClass);
      }
    });

    $("input.singledate").on("focus", function () {
      const $input = $(this);

      if (activeInput && activeInput[0] !== $input[0]) {
        const oldDaterangepicker = activeInput.data("daterangepicker");
        oldDaterangepicker.hide();
      }

      activeInput = $input;

      const currentDaterangepicker = $input.data("daterangepicker");
      currentDaterangepicker.show();
    });

    $("input.singledate").on("blur", function () {
      const $input = $(this);

      setTimeout(function () {
        if (activeInput && activeInput[0] === $input[0]) {
          const currentDaterangepicker = activeInput.data("daterangepicker");
          currentDaterangepicker.hide();
          activeInput = null;
        }
      }, 200);
    });

    $(document).on("mousedown", ".daterangepicker", function (e) {
      e.preventDefault();
    });

    $(document).on("mousedown", function (e) {
      if (
        !$(e.target).closest(".daterangepicker").length &&
        !$("input.singledate").is(e.target)
      ) {
        if (activeInput) {
          const currentDaterangepicker = activeInput.data("daterangepicker");
          currentDaterangepicker.hide();
          activeInput = null;
        }
      }
    });

    $(document).on('click', '.form-short-key .btn-cancel', function (e) {
      e.preventDefault();
      $('#confirm_back_modal').modal('show');
    });

    $(document).on('click', '.js-confirm-back-modal-ok-btn', function (e) {
      e.preventDefault();
      const redirectUrl = $('.btn-cancel').attr('href');
      window.location.href = redirectUrl;
    });

    $(document).on('click', '.js-confirm-back-modal-cancel-btn', function (e) {
      e.preventDefault();
      $('#confirm_back_modal').modal('hide');
      $('.btns-control .btn-cancel').focus();
    });

    $(document).on('click', '.form-short-key .btn-cancel', function (e) {
      e.preventDefault();
      $('#confirm_back_modal').modal('show');
    });

    $(document).on('keydown', function (e) {
      if (e.key === 'Escape') {
        const isFieldFocused = $(':focus').length > 0;

        if (!isFieldFocused) {
          $('#confirm_back_modal').modal('show');
        }
      }
    });
  });

  // Delete item
  $(document).on('click', '.js-confirm-delete-order', function (e) {
    e.preventDefault();
    const itemId = $(this).closest('.row').data('item-id');

    $('#confirm_delete_modal').modal('show');
    $('#confirm_delete_modal').data('item-id', itemId);
  });

  $('#confirm_delete_modal').on('shown.bs.modal', function () {
    const $cancelButton = $(this).find('.js-confirm-delete-modal-cancel-btn');
    if ($cancelButton.length > 0) {
      $cancelButton.focus();
    }
    trapFocus($(this));
  });

  $('#confirm_back_modal').on('shown.bs.modal', function () {
    const $cancelButton = $(this).find('.js-confirm-back-modal-cancel-btn');
    if ($cancelButton.length > 0) {
      $cancelButton.focus();
    }
    trapFocus($(this));
  });

  $('.js-add-order-items-data-modal').on('shown.bs.modal', function () {
    trapFocus($(this));
  });

  $(document).on('click', '.js-confirm-delete-modal-ok-btn', function () {
    const itemId = $('#confirm_delete_modal').data('item-id');
    let currentRow = null;
    let nextRow = null;
    let prevRow = null;

    $('.row').each(function () {
      if ($(this).data('item-id') == itemId) {
        currentRow = $(this);
        nextRow = currentRow.next('.row');
        prevRow = currentRow.prev('.row');
        $(this).remove();
        return false;
      }
    });

    $('#confirm_delete_modal').modal('hide');
    if (nextRow && nextRow.length > 0) {
      const $nextItem = nextRow.find('.js-source-products-ajax');
      if ($nextItem.length > 0) {
        focusOnProductSelect(nextRow);
        return;
      }
    }

    if (prevRow && prevRow.length > 0) {
      const $prevItem = prevRow.find('.js-source-products-ajax');
      if ($prevItem.length > 0) {
        focusOnProductSelect(prevRow);
        return;
      }
    }
  });

  $(document).on('click', '.js-confirm-delete-modal-cancel-btn', function () {
    const itemId = $('#confirm_delete_modal').data('item-id');

    $('#confirm_delete_modal').modal('hide');
    if (itemId) {
      const $deleteButton = $('.row[data-item-id="' + itemId + '"]').find('.js-confirm-delete-order');
      if ($deleteButton.length) {
        $deleteButton.focus();
      }
    }
    $('#confirm_delete_modal').data('item-id', "");
  });

  // dialog order histories
  $(document).ready(function () {
    var $modal = $('.js-add-order-items-data-modal');
    $modal.on('focus', '.js-item-checkbox', function () {
      $(this).off('keydown').on('keydown', function (e) {
        if (e.key === 'Enter') {
          e.preventDefault();
          var productName = $(this).data("product-name");
          var isChecked = $(this).prop("checked");
          $.each($(".order-items-data .js-item-checkbox:checked"), function (i, el) {
            if (productName == $(el).data("product-name")) {
              $(el).prop('checked', false).trigger('change');
            }
          });

          $(this).prop('checked', !isChecked).trigger('change');
        }
      });
    });

    $modal.find('.js-close-modal-add-order-items').on('keydown', function (event) {
      if (event.key === 'Enter') {
        $modal.modal('hide');
      }
    });

    $modal.find('.js-check-duplicate-product-btn').on('keydown', function (event) {
      if (event.key === 'Enter') {
        submitForm();
      }
    });

    $modal.find('.js-append-order-items-checked-btn').on('keydown', function (event) {
      if (event.key === 'Enter') {
        submitForm();
      }
    });

    function submitForm() {
      $('.js-check-duplicate-product-btn').trigger('click');
    }
  });

  // add uuid to new item
  $(document).on('cocoon:after-insert', function (e, insertedItem) {
    const itemId = crypto.randomUUID();
    insertedItem.data('item-id', itemId);
    insertedItem.attr('data-item-id', itemId);
  });

  // reset select2 when hidden modal
  $(document).on('hidden.bs.modal', '#confirm_delete_modal, #confirm_back_modal', function () {
    resetSelect2();
  });

  function handleFocusOrderHistoryModal($modal) {
    const $firstCheckbox = $modal.find('.js-item-checkbox').first();
    if ($firstCheckbox.length > 0) {
      setTimeout(function () {
        $firstCheckbox.trigger('focus');
      }, 100);
    } else {
      const $cancelButton = $modal.find('.js-close-modal-add-order-items').first();
      if ($cancelButton.length > 0) {
        $cancelButton.focus();
      }
    }
  }
  $(document).on('shown.bs.modal', '.js-add-order-items-data-modal', function () {
    handleFocusOrderHistoryModal($(this).find('.modal-content'));
  });

  $(document).on('shown.bs.modal', '.js-confirm-fill-products-modal', function () {
    const $cancelButton = $(this).find('.js-cancel-btn');
    if ($cancelButton.length > 0) {
      $cancelButton.focus();
    }
    trapFocus($(this));
  });
});

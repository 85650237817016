import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import { numberWithCommas } from "./custom_format_input_number.js";

$(document).on('ready turbolinks:load', function () {
  handleSelectProductCategory();
  handleProductSelect();

  $('.product-form-inline input.js-product-amount').keyup(function (event) {
    let configure_value = 0.15;
    let current_input = $(this).val().trim();

    if ($.isNumeric(current_input)) {
      let item_amount_converted = parseFloat(current_input);
      var manufacturing_cost = (item_amount_converted * configure_value).toFixed(2);

      $('.product-form-inline .js-manufacturing_cost-field input.int-field').val(manufacturing_cost);
    } else {
      $('.product-form-inline .js-manufacturing_cost-field input.int-field').val('');
    }
  });

  let checked_product = localStorage.getItem("checked_product");

  if (checked_product) {
    $('button.js-btn-update-product-sell-status').prop('disabled', false);

    if (checked_product == 'check_all') {
      $('input.js-checked-product-ids').val('check_all');

      var checked_products = $('input[name=checkbox-product]');
      for (let i = 0; i < checked_products.length; i++) {
        $(checked_products[i]).prop('checked', true);
      }
    } else {
      $('input.js-checked-product-ids').val(checked_product);

      let checked_product_ids = checked_product.split(",");
      var checked_products = $('input[name=checkbox-product]');
      for (let i = 0; i < checked_products.length; i++) {
        if (checked_product_ids.includes($(checked_products[i]).val())) {
          $(checked_products[i]).prop('checked', true);
        }
      }
    }
  } else {
    $('button.js-btn-update-product-sell-status').prop('disabled', true);
  }

  $('.js-checkbox-product').change(function() {
    var is_checked_all = false;

    if(this.checked) {
      $('button.js-btn-update-product-sell-status').prop('disabled', false);

      if ($(this).val() == "check_all") {
        is_checked_all = true;

        var checked_products = $('input[name=checkbox-product]');
        for (let i = 0; i < checked_products.length; i++) {
          $(checked_products[i]).prop('checked', true);
        }
      } else {
        var current_product_id = $(this).val();
        var product_ids_storage = localStorage.getItem("checked_product");
        var arr_product_ids_storage = [];

        if (product_ids_storage != 'check_all') {
          if (product_ids_storage) {
            arr_product_ids_storage = product_ids_storage.split(",");
          }
          arr_product_ids_storage.push(current_product_id);
          localStorage.setItem("checked_product", arr_product_ids_storage.join(","));
        }
      }
    } else {
      if ($(this).val() == "check_all") {
        is_checked_all = false;
        localStorage.setItem("checked_product", "");

        var checked_products = $('input[name=checkbox-product]');
        for (let i = 0; i < checked_products.length; i++) {
          $(checked_products[i]).prop('checked', false);
        }
      } else {
        var current_product_id = $(this).val();
        var product_ids_storage = localStorage.getItem("checked_product");

        if (product_ids_storage != 'check_all') {
          var arr_product_ids_storage = product_ids_storage.split(",");
          let index = arr_product_ids_storage.indexOf(current_product_id);

          if (index > -1) { // only splice array when item is found
            arr_product_ids_storage.splice(index, 1); // 2nd parameter means remove one item only
          }

          localStorage.setItem("checked_product", arr_product_ids_storage.join(","));
        }
      }
    }

    if (is_checked_all) {
      $('input.js-checked-product-ids').val('check_all');
      localStorage.setItem("checked_product", "check_all");
    } else {
      var product_ids_storage = localStorage.getItem("checked_product");

      if (product_ids_storage) {
        $('button.js-btn-update-product-sell-status').prop('disabled', false);
      } else {
        $('button.js-btn-update-product-sell-status').prop('disabled', true);
      }

      $('input.js-checked-product-ids').val(product_ids_storage);
    }
  });

});

$(document).on('click', '.js-btn-update-product-sell-status', function (e) {
  e.preventDefault();

  let checked_shop = localStorage.getItem("checked_product");

  if (checked_shop) {
    $('.js-update-product-sell-status-modal').modal('show');
  }
});

function handleSelectProductCategory() {
  $('.product-form-inline .js-source-categories').on('select2:select', function (e) {
    var data = e.params.data;

    $.ajax({
      url: '/categories/' + data.id,
      method: 'GET',
    }).done(function (rsp) {
      if (rsp.product_type == 'noodle') {
        $('.product-form-inline .js-manufacturing_cost-field').removeClass('d-none');
      } else {
        $('.product-form-inline .js-manufacturing_cost-field').addClass('d-none');
      }
    });
  });
}

function handleProductSelect() {
  $(".stock-products-search-group .js-source-stock-doughs").on('select2:select', function (e) {
    $(".btn-stock-product-search").trigger("click");
  });
}

$(document).on('click', '.js-open-stock-produces-modal-btn', function (e) {
  e.preventDefault();

  var productId = $(this).closest(".item-row").data("produce-order-id");
  getProduces(productId);
});

$(document).on('click', '.js-close-modal-stock-produces-detail', function (e) {
  e.preventDefault();

  $('.js-stock-produces-detail-modal').modal('hide');
});

$(document).on('click', '.js-open-eliminate-modal', function (e) {
  e.preventDefault();

  var produceId = $(this).closest(".js-produces-data-row").data("produce-id");

  getProducesDetail(produceId);
});

$(document).on('click', '.js-close-eliminates-modal', function (e) {
  e.preventDefault();

  $('.js-eliminates-modal').modal('hide');
});

$(document).on('keyup', '.js-eliminate-amount', function () {
  var produceAmount = $(".eliminates-data .js-eliminates-available").val();

  if (parseInt($(this).val()) > produceAmount) {
    $(".field-error").html("在庫は足りません。");
    disableEliminateBtn()
  } else {
    enableEliminateBtn()
  }
});

function disableEliminateBtn() {
  $(".field-error").removeClass("d-none");
  $('.js-btn-eliminate-ok').prop('disabled', true).css("opacity", "0.5");
  $(this).addClass("error-input")
}

function enableEliminateBtn() {
  $(".field-error").addClass("d-none");
  $('.js-btn-eliminate-ok').prop('disabled', false).css("opacity", "1");
  $(this).removeClass("error-input")
}

$(document).on('click', '.js-eliminates-modal .js-btn-eliminate-ok', function (e) {
  e.preventDefault();

  if ($(".js-eliminate-amount").val() == '') {
    $(".field-error").html("は必須です。");
    disableEliminateBtn()
  } else {
    enableEliminateBtn();
    var produceId = $(".js-produce-id").val();
    createEliminates(produceId);
  }
});

function getProduces(productId) {
  if (productId != "") {
    $.ajax({
      url: '/produces/by_product_id',
      method: 'GET',
      data: {product_id: productId, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function (response) {
      $(".js-stock-produces-detail-modal #stock-products-produces-data").html("");
      var str = "";
      if (response.produces.length > 0) {
        $.each(response.produces, function (i, produce) {
          var isExpired = produce.is_expired ? 'text-red' : '';
          var display_product_amount = numberWithCommas(produce.amount);
          var display_total_eliminates = numberWithCommas(produce.total_eliminates);

          str += `<tr class="js-produces-data-row ${isExpired}" data-produce-id="${produce.id}">
                    <td class="col-3 text-center v-align-midle">
                      ${produce.created_at}
                    </td>
                    <td class="col-3 text-center v-align-midle js-produce-order-amount" data-produce-amount="${produce.amount}">
                      ${display_product_amount}${produce.unit}
                    </td>
                    <td class="col-3 text-center js-total-eliminates v-align-midle">
                      ${display_total_eliminates}
                    </td>
                    <td class="col-3 text-center v-align-midle">`;
                      if (produce.total_eliminates < produce.amount) {
                        str += `<a class="js-open-eliminate-modal btn btn-sm btn-danger text-white" style="width: 100%; padding: 3px;">廃棄</a>`
                      }
          str += `</td>`;
          str += `</tr>`;
        })
      }

      $(".js-stock-produces-detail-modal tbody#stock-products-produces-data").append(str);
      $('.js-stock-produces-detail-modal').modal('show');
    });
  }
};

function getProducesDetail(produceId) {
  if (produceId != "") {
    $.ajax({
      url: '/produces/by_id',
      method: 'GET',
      data: {id: produceId, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function (response) {
      var produce = response.produce;

      $(".eliminates-data .js-product-name-eliminate").html(produce.product_name);
      $(".eliminates-data .js-produce-created-date").html(produce.created_at);
      $(".eliminates-data .js-produce-amount").html(`${produce.amount}${produce.unit}`);
      $(".eliminates-data .js-eliminates-available").val(produce.amount_available);
      $(".eliminates-data .js-eliminate-amount").val("");
      $(".eliminates-data .js-eliminate-reason").val("");
      $(".js-produce-id").val(produceId);

      $('.js-eliminates-modal').modal('show');
    });
  }
}

function createEliminates(produceId) {
  if (produceId != "") {
    var amount = $(".js-eliminates-modal .js-eliminate-amount").val();
    var reason = $(".js-eliminates-modal .js-eliminate-reason").val();

    $.ajax({
      url: '/produces/create_eliminate',
      method: 'POST',
      data: {
        produce_id: produceId,
        amount: amount,
        reason: reason,
        'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      }
    }).done(function (response) {
      var produce = response.data;

      $('.js-eliminates-modal').modal('hide');

      var parentEl = $(".modal-produces-eliminates-items #stock-products-produces-data").find(`[data-produce-id="${produceId}"]`);
      $(parentEl).find(".js-total-eliminates").html(produce.eliminate_amount);
      var produceAmount = $(parentEl).find(".js-produce-order-amount").data("produce-amount");
      if (produceAmount == produce.eliminate_amount) {
        $(parentEl).find(".js-open-eliminate-modal").addClass("d-none");
      }
      var err_str = `<div class="alert alert-success text-center js-flash-msg-area">保存に成功しました。</div>`;
      $(".js-stock-produces-detail-modal .err-block").html("").append(err_str);
      $('.js-flash-msg-area').fadeIn('fast');

      setTimeout(function () {
        $('.js-flash-msg-area').fadeOut('fast');
      }, 3000);
    });
  }
}

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

$(document).on('ready turbolinks:load', function () {
  setInterval(refreshPageAjax, 5000);

  $(".produce-doughs-sortable").sortable({
    items: ".item-row",
    update: function (event, ui) {
      let sortable_wrapper = ui.item.parents('.produce-doughs-sortable');
      let items = sortable_wrapper.find('.item-row');

      for (let i = 0; i < items.length; i++) {
        let item_index = items.length - i;

        $(items[i]).find('input.js-sort-index-val').val(item_index);

        var id = $(items[i]).data("item-id");
        $.ajax({
          url: '/manufacturing/doughs/update_sort_index',
          method: 'POST',
          data: {
            id: id, sort_index: item_index,
            'authenticity_token': $('meta[name="csrf-token"]').attr('content')
          }
        }).done(function (response) {
        });
      }
    }
  });

  $('input.js-custom-volume-dough-field').keyup(function (event) {
    let current_input = $(this).val().trim();

    var table_description_title = 'レシピ(' + current_input + 'kgあたりの原材料)';

    if ($.isNumeric(current_input)) {
      $('.js-table-description-title').html(table_description_title);
    } else {
      $('.js-table-description-title').html('レシピ(1kgあたりの原材料)');
    }
  });

  $(document.body).on("change", ".js-dough-materials-select", function () {
    var id = $(this).val();
    var $row_item = $(this).parent().parent();

    if (id != "") {
      $.ajax({
        url: '/materials/unit_by_id',
        method: 'GET',
        data: {
          id: id,
          'authenticity_token': $('meta[name="csrf-token"]').attr('content')
        }
      }).done(function (response) {
        $($row_item).find(".material-unit").html(response.data.unit);
      });
    }
  });

  $(document).on('click', '.js-btn-open-update-status-modal', function (e) {
    e.preventDefault();

    var doughId = $(this).data("item-id");

    $('.js-confirm-achievement-dough-modal').find(".js-dough-id").val(doughId);
    $('.js-confirm-achievement-dough-modal').modal('show');
  });

  $(document).on('click', '.js-btn-open-cancel-achievement-dough-modal', function (e) {
    e.preventDefault();

    var doughId = $(this).data("item-id");

    $('.js-cancel-achievement-dough-modal').find(".js-dough-id").val(doughId);
    $('.js-cancel-achievement-dough-modal').modal('show');
  });
});

$(document).ready(function () {
  $(document).on('click', '.js-btn-ok-update-status', function (e) {
    e.preventDefault();

    var produceOrderId = $('.js-confirm-achievement-dough-modal').find(".js-dough-id").val();
    var updateMethod = $(this).data("update-method");

    updateStatusAjax(produceOrderId, updateMethod);
  });

  $(document).on('click', '.js-btn-cancel-status', function (e) {
    e.preventDefault();

    var produceOrderId = $('.js-cancel-achievement-dough-modal').find(".js-dough-id").val();
    var updateMethod = $(this).data("update-method");

    updateStatusAjax(produceOrderId, updateMethod);
  });

  $(document).on('click', '.js-create-dough-btn', function (e) {
    e.preventDefault();

    var params = [];
    $.each($(".js-create-produce-doughs-modal .item-row"), function (i, item) {
      var doughId = $(item).find('.js-dough-id').val();
      var doughAmount = $(item).find('.js-dough-amount').val();
      params.push({dough_id: doughId, amount: doughAmount})
    });

    $.ajax({
      url: '/manufacturing/doughs',
      method: 'POST',
      data: {
        doughs: params,
        'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function (response) {
        $(this).prop('disabled', true);
        if (response.data != undefined && response.data.length > 0) {
          $(".field-error").addClass("d-none");

          $.each(response.data, function (i, dough) {
            $(`.js-field-error-amount-${dough.item}`).empty();
            $(`.js-field-error-amount-${dough.item}`).append(`<div>在庫は足りません。</div>`)
            $(`.js-field-error-amount-${dough.item}`).removeClass("d-none");
            for (let i = 0; i < dough.message.length; i++) {
              $(`.js-field-error-amount-${dough.item}`).append(`<div>${dough.message[i]}</div>`)
            }
          })
        } else {
          $('.js-create-produce-doughs-modal').modal('hide');
          $('.js-flash-msg-area').fadeIn('fast');
          setTimeout(function() {
            $('.js-flash-msg-area').fadeOut('fast');
            location.reload();
          }, 2000);
        }

        $(this).prop('disabled', false);
      }
    })
  })
});

function updateStatusAjax(produceOrderId, updateMethod) {
  if (produceOrderId != "") {
    $.ajax({
      url: '/manufacturing/doughs/update_status',
      method: 'POST',
      data: {
        produce_order_id: produceOrderId,
        update_method: updateMethod,
        'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      },
    }).done(function (response) {
      location.reload();
    });
  }
}

function refreshPageAjax() {
  if (location.pathname == "/manufacturing/doughs") {
    $.ajax({
      url: '/manufacturing/doughs/refresh',
      method: 'GET',
      data: {
        'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      }
    }).done(function (response) {
      var datas = response.data;
      $.each(datas, function (i) {
        var parentEl = $(`.doughs-list .item-row-${datas[i].id}`);
        $(parentEl).find(".js-produce-order-amount").html(`${datas[i].amount}kg`);
        $(parentEl).find(".js-produce-order-status").html(datas[i].status);
        if (datas[i].status != "") {
          $(parentEl).find(".js-btn-update-dough").addClass("d-none");
        }
      });
    });
  }
}
